import style from "./sidebar.module.scss";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useTranslation } from "react-i18next";
import Img from "../../utils/img/Img";
import { HashLink } from 'react-router-hash-link';



function SidebarAboutUs({ active }) {
  // change language handler
  const { t } = useTranslation();



  const navigate = useNavigate();

  // Modify handleNavigation to use navigate function
  const handleNavigation = (path) => {
    navigate(path);
    window.location.reload(); // Reload the page if needed
  };

  return (
    <>
      {active ? (
        <div className={style.sidebar}>
          <p className={style.sidebar_exit} onClick={active}>
            X
          </p>
          <Container>
            <Row>
              <Col md={4} xs={6} className="order-1 mb-md-0 mb-5">
                <div className={style.sidebar_content}>
                  <h2 className={style.sidebar_content_title}>{t("about")}</h2>
                  <p className={style.sidebar_content_desc}>{t("aboutUsText")}</p>
                </div>
              </Col>
              <Col md={4} xs={12} className="order-3 order-md-2">
                <ul className={`list-unstyled ${style.sidebar_links}`}>
                  <li className={style.sidebar_links_item}>
                    <NavDropdown
                      title={t("groupOverview")}
                      id="basic-nav-dropdown"
                      className={style.sidebar_links_item_dropdown}
                    >
                      <HashLink onClick={active} className={style.sidebar_links_item_dropdown_link} to="/about/group-overview#headerpurpose">
                        {/* {t("headerpurpose")} */}
                        <span onClick={() => handleNavigation('/about/group-overview#headerpurpose')}>{t("headerpurpose")}</span>

                        
                      </HashLink>
                      <HashLink onClick={active} className={style.sidebar_links_item_dropdown_link} to="/about/group-overview#whatWeDo">
                        {t("whoweare")}
                      </HashLink>
                      <HashLink onClick={active} className={style.sidebar_links_item_dropdown_link} to="/about/group-overview#ourBrings">
                        {t("whatWeBringYou")}
                      </HashLink>
                      <HashLink onClick={active} className={style.sidebar_links_item_dropdown_link} to="/about/group-overview#OurValues">
                        {t("ourValues")}
                      </HashLink>
                      <HashLink onClick={active} className={style.sidebar_links_item_dropdown_link} to="/about/group-overview#figures">
                        {t("nahdetMistInFigures")}
                      </HashLink>
                      <HashLink onClick={active} className={style.sidebar_links_item_dropdown_link} to="/about/group-overview#OurPartners">
                        {t("OurPartners")}
                      </HashLink>


                    </NavDropdown>
                  </li>
                  <li className={style.sidebar_links_item} onClick={active}>
                    <Link to="about/subsidiaries">{t("subsidiaries")}</Link>
                  </li>
                  <li className={style.sidebar_links_item} onClick={active}>
                    <Link to="about/markets">{t("markets")}</Link>
                  </li>
                  <li className={style.sidebar_links_item} onClick={active}>
                    <Link to="about/awards">{t("awards")}</Link>
                  </li>
                  <li className={style.sidebar_links_item} onClick={active}>
                    <Link to="about/social-impact">{t("socialImpact")}</Link>
                  </li>
                  <li className={style.sidebar_links_item} onClick={active}>
                    <Link to="about/history">{t("HistoryOurStory")}</Link>
                  </li>
                </ul>
              </Col>
              <Col md={4} xs={6} className="order-2 order-md-3">
                <Img src="/aboutSidebar.jpg"search alt="img" className={`img-fluid ${style.sidebar_img}`} />
              </Col>
            </Row>
          </Container>
          {/* <Img src="/pattern.png" alt="img" className={style.sidebar_pattern} /> */}
        </div>
      ) : (
        ""
      )}
    </>
  );
}

function SidebarEducationalInstitutions({ active }) {
  // change language handler
  const { t } = useTranslation();

  const navigate = useNavigate();

  // Modify handleNavigation to use navigate function
  const handleNavigation = (path) => {
    navigate(path);
    window.location.reload(); // Reload the page if needed
  };

  return (
    <>
      {active ? (
        <div className={style.sidebar}>
          <p className={style.sidebar_exit} onClick={active}>
            X
          </p>
          <Container>
            <Row>
              <Col md={4} xs={6} className="order-1 mb-md-0 mb-5">
                <div className={style.sidebar_content}>
                  <h2 className={style.sidebar_content_title}>{t("EducationalInstitutions")}</h2>
                  <p className={style.sidebar_content_desc}>{t("educationalInstitutionsText")}</p>
                </div>
              </Col>
              <Col md={4} xs={12} className="order-3 order-md-2">
                <ul className={`list-unstyled ${style.sidebar_links}`}>
                  <li className={style.sidebar_links_item} onClick={active}>
                    <Link to="educational-institutions/printing-academy">
                    {/* {t("printingAcademy")} */}
                    <span onClick={() => handleNavigation('/educational-institutions/printing-academy')}>{t("printingAcademy")}</span>
                    </Link>
                  </li>
                  <li className={style.sidebar_links_item} onClick={active}>
                    <Link to="educational-institutions/applied-technology-school">
                    {/* {t("appliedTechnologySchool")} */}
                    <span onClick={() => handleNavigation('/educational-institutions/applied-technology-school')}>{t("appliedTechnologySchool")}</span>
                    </Link>
                  </li>
                  <li className={style.sidebar_links_item} onClick={active}>
                    <Link to="educational-institutions/international-academy">
                    {/* {t("internationalAcademy")} */}
                    <span onClick={() => handleNavigation('/educational-institutions/international-academy')}>{t("internationalAcademy")}</span>
                    </Link>
                  </li>
                </ul>
              </Col>
              <Col md={4} xs={6} className="order-2 order-md-3">
                <Img src="/servicesSidebar.jpg" alt="img" className={`img-fluid ${style.sidebar_img}`} />
              </Col>
            </Row>
          </Container>
          {/* <Img src="/pattern.png" alt="img" className={style.sidebar_pattern} /> */}
        </div>
      ) : (
        ""
      )}
    </>
  );
}
function SidebarEducationalCertificates({ active }) {
  // change language handler
  const { t } = useTranslation();

  const navigate = useNavigate();

  // Modify handleNavigation to use navigate function
  const handleNavigation = (path) => {
    navigate(path);
    window.location.reload(); // Reload the page if needed
  };

  return (
    <>
      {active ? (
        <div className={style.sidebar}>
          <p className={style.sidebar_exit} onClick={active}>
            X
          </p>
          <Container>
            <Row>
              <Col md={4} xs={6} className="order-1 mb-md-0 mb-5">
                <div className={style.sidebar_content}>
                  <h2 className={style.sidebar_content_title}>{t("EducationalCertificates")}</h2>
                </div>
              </Col>
              <Col md={4} xs={12} className="order-3 order-md-2">
                <ul className={`list-unstyled ${style.sidebar_links}`}>
                  <li className={style.sidebar_links_item} onClick={active}>
                    <Link to="educational-certificates/exam-center">
                    {/* {t("nahdetMisrExamCenter")} */}
                    <span onClick={() => handleNavigation('/educational-certificates/exam-center')}>{t("nahdetMisrExamCenter")}</span>
                    </Link>
                  </li>
                </ul>
              </Col>
              <Col md={4} xs={6} className="order-2 order-md-3">
                <Img src="/SidebarEducationalCertificates.jpg" alt="img" className={`img-fluid ${style.sidebar_img}`} />
              </Col>
            </Row>
          </Container>
          {/* <Img src="/pattern.png" alt="img" className={style.sidebar_pattern} /> */}
        </div>
      ) : (
        ""
      )}
    </>
  );
}

function SidebarOffering({ active, handleSidebarLinkClick }) {
  // change language handler
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleTabClick = (tab) => {
    navigate('/solutions/k-12#', { state: { activeTab: tab } });
    active(); // Close the sidebar
    window.location.reload(); // Force reload
  };
  const handleHigherEducationClick = (tab) => {
    navigate('/solutions/higher-education', { 
      state: { activeTab: tab }  // Pass the active tab as state
    });
    active(); // Close the sidebar
    window.location.reload(); // Force reload
  };
  const handlePublishingClick = (tab) => {
    navigate('/solutions/publishing', { 
      state: { activeTab: tab }  // Pass the active tab as state
    });
    active(); // Close the sidebar
    window.location.reload(); // Force reload
  };

  const handleServicesClick = () => {
    navigate('/solutions/vocational-education');
    active(); // Close the sidebar
  };
  const handlecorporatesClick = () => {
    navigate('/solutions/corporates');
    active(); // Close the sidebar
  };
  return (
    <>
      {active ? (
        <div className={style.sidebar}>
          <p className={style.sidebar_exit} onClick={active}>
            X
          </p>
          <Container>
            <Row>
              <Col lg={2} xs={6} className="order-1 mb-lg-0 mb-2">
                <div className={style.sidebar_content}>
                  <h2 className={style.sidebar_content_title}>{t("solutions")}</h2>
                  <p className={style.sidebar_content_desc}>{t("offeringText")}</p>
                </div>
              </Col>
              <Col lg={7} md={9} xs={12} className="order-3 order-md-2">
                <ul className={`list-unstyled ${style.sidebar_links}`}>
                  <li className={style.sidebar_links_item}>
                    <NavDropdown title={t("k12")} id="basic-nav-dropdown" className={style.sidebar_links_item_dropdown}>
                      <Row>
                        <Col xs={6}>
                        <h6 
                        className={style.sidebar_links_item_dropdown_title}
                        onClick={() => handleTabClick('services')}
                        style={{ cursor: 'pointer' }}
                      >
                        {t("services")}
                        
                      </h6>

                          <HashLink  onClick={(e) => {handleSidebarLinkClick("Services"); active(e)}} className={style.sidebar_links_item_dropdown_link} to="/solutions/k-12#services-curricula-development">
                            {t("CurriculaDevelopmentlink")}
                          </HashLink>
                          <HashLink onClick={(e) => {handleSidebarLinkClick("Services"); active(e)}} className={style.sidebar_links_item_dropdown_link} to="/solutions/k-12#services-Translation">
                            {t("translationLocalization")}
                          </HashLink>
                          <HashLink onClick={(e) => {handleSidebarLinkClick("Services"); active(e)}} className={style.sidebar_links_item_dropdown_link} to="/solutions/k-12#services-capability">
                            {t("capabilityLink")}
                          </HashLink>
                          <HashLink onClick={(e) => {handleSidebarLinkClick("Services"); active(e)}} className={style.sidebar_links_item_dropdown_link} to="/solutions/k-12#services-digitization-of-content">
                            {t("DigitizationContent")}
                          </HashLink>
                          <HashLink onClick={(e) => {handleSidebarLinkClick("Services"); active(e)}} className={style.sidebar_links_item_dropdown_link} to="/solutions/k-12#learning-management-systems">
                            {t("LearningManagementSystems")}
                          </HashLink>
                          <HashLink onClick={(e) => {handleSidebarLinkClick("Services"); active(e)}} className={style.sidebar_links_item_dropdown_link} to="/solutions/k-12#mobile-applications-development">
                            {t("MobileApplicationsDevelopment")}
                          </HashLink>
                          <HashLink onClick={(e) => {handleSidebarLinkClick("Services"); active(e)}} className={style.sidebar_links_item_dropdown_link} to="/solutions/k-12#digital-strategy-and-consultation">
                            {t("DigitalStrategyConsultation")}
                          </HashLink>

                        </Col>
                        <Col xs={6}>
                        <h6 
                        className={style.sidebar_links_item_dropdown_title}
                        onClick={() => handleTabClick('products')}
                        style={{ cursor: 'pointer' }}
                      >
                        {t("Products")}
                      </h6>

                          <HashLink onClick={(e) => {handleSidebarLinkClick("Products"); active(e)}} className={style.sidebar_links_item_dropdown_link} to="/solutions/k-12#Al-Adwaa">
                            {t("AlAdwaa")}
                          </HashLink>
                          <HashLink onClick={(e) => {handleSidebarLinkClick("Products"); active(e)}} className={style.sidebar_links_item_dropdown_link} to="/solutions/k-12#Rehlatie">
                            {t("RehlatieApplication")}
                          </HashLink>
                          <HashLink onClick={(e) => {handleSidebarLinkClick("Products"); active(e)}} className={style.sidebar_links_item_dropdown_link} to="/solutions/k-12#qessatie">
                            {t("QessatieApplication")}
                          </HashLink>
                          <HashLink onClick={(e) => {handleSidebarLinkClick("Products"); active(e)}} className={style.sidebar_links_item_dropdown_link} to="/solutions/k-12#international-curricula-programs">
                            {t("InternationalCurriculaPrograms")}
                          </HashLink>
                          <HashLink onClick={(e) => {handleSidebarLinkClick("Products"); active(e)}} className={style.sidebar_links_item_dropdown_link} to="/solutions/k-12#leveled-readers">
                            {t("LeveledReaders")}
                          </HashLink>
                          <HashLink onClick={(e) => {handleSidebarLinkClick("Products"); active(e)}} className={style.sidebar_links_item_dropdown_link} to="/solutions/k-12#calligraphy-books">
                            {t("CalligraphyBooks")}
                          </HashLink>
                          <HashLink onClick={(e) => {handleSidebarLinkClick("Products"); active(e)}} className={style.sidebar_links_item_dropdown_link} to="/solutions/k-12#life-skills-and-character-development">
                            {t("LifeSkillsCharacterDevelopment")}
                          </HashLink>
                          <HashLink onClick={(e) => {handleSidebarLinkClick("Products"); active(e)}} className={style.sidebar_links_item_dropdown_link} to="/solutions/k-12#early-childhood-programs">
                            {t("EarlyChildhoodPrograms")}
                          </HashLink>
                          <HashLink onClick={(e) => {handleSidebarLinkClick("Products"); active(e)}} className={style.sidebar_links_item_dropdown_link} to="/solutions/k-12#islamic-religion-curriculum">
                            {t("IslamicReligionCurricula")}
                          </HashLink>
                          <HashLink onClick={(e) => {handleSidebarLinkClick("Products"); active(e)}} className={style.sidebar_links_item_dropdown_link} to="/solutions/k-12#school-supplies">
                            {t("SchoolSupplies")}
                          </HashLink>

                        </Col>
                      </Row>
                    </NavDropdown>
                  </li>
                  <li className={style.sidebar_links_item}>
                    <NavDropdown
                      title={t("HigherEducation")}
                      id="basic-nav-dropdown"
                      className={style.sidebar_links_item_dropdown}
                    >
                      <Row>
                        <Col xs={6}>
                          <h6 className={style.sidebar_links_item_dropdown_title}
                          onClick={() => handleHigherEducationClick('services')}
                          style={{ cursor: 'pointer' }}
                          >{t("services")}</h6>
                          <HashLink onClick={(e) => {handleSidebarLinkClick("Services"); active(e)}} className={style.sidebar_links_item_dropdown_link} to="/solutions/higher-education#digitization-of-content">
                            {t("DigitizationContent")}
                          </HashLink>
                          <HashLink onClick={(e) => {handleSidebarLinkClick("Services"); active(e)}} className={style.sidebar_links_item_dropdown_link} to="/solutions/higher-education#capability-building-for-educators">
                            {t("ProfessionalDevelopmentEducators")}
                          </HashLink>

                        </Col>
                        <Col xs={6}>
                          <h6 className={style.sidebar_links_item_dropdown_title}
                          onClick={() => handleHigherEducationClick('products')}
                          style={{ cursor: 'pointer' }}
                          >{t("Products")}</h6>
                          <HashLink onClick={(e) => {handleSidebarLinkClick("Products"); active(e)}} className={style.sidebar_links_item_dropdown_link} to="/solutions/higher-education#elt">
                            {t("elt")}
                          </HashLink>

                        </Col>
                      </Row>
                    </NavDropdown>
                  </li>
                  <li className={style.sidebar_links_item}>
                    <NavDropdown
                      title={t("vocational")}
                      id="basic-nav-dropdown"
                      className={style.sidebar_links_item_dropdown}
                    >
                      <h6 
                        className={style.sidebar_links_item_dropdown_title}
                        onClick={handleServicesClick} // Navigate on click
                        style={{ cursor: 'pointer' }}
                      >
                        {t("services")}
                      </h6>

                      <HashLink onClick={active} className={style.sidebar_links_item_dropdown_link} to="/solutions/vocational-education#governance-and-policy">
                        {t("GovernancePolicy")}
                      </HashLink>
                      <HashLink onClick={active} className={style.sidebar_links_item_dropdown_link} to="/solutions/vocational-education#private-sector-engagement">
                        {t("QualityManagement")}
                      </HashLink>
                      <HashLink onClick={active} className={style.sidebar_links_item_dropdown_link} to="/solutions/vocational-education#private-sector-engagement">
                        {t("PrivatesectorEngagement")}
                      </HashLink>
                      <HashLink onClick={active} className={style.sidebar_links_item_dropdown_link} to="/solutions/vocational-education#technical-schools-management-and-operations">
                        {t("TechnicalSchoolsManagementOperations")}
                      </HashLink>
                      <HashLink onClick={active} className={style.sidebar_links_item_dropdown_link} to="/solutions/vocational-education#curricula-development">
                        {t("CurriculaDevelopmentlink")}
                      </HashLink>
                      <HashLink onClick={active} className={style.sidebar_links_item_dropdown_link} to="/solutions/vocational-education#capability-building-for-vocational-educators">
                        {t("CapabilityBuildingVocationalEducators")}
                      </HashLink>
                      <HashLink onClick={active} className={style.sidebar_links_item_dropdown_link} to="/solutions/vocational-education#digitization-of-content">
                        {t("DigitizationContent")}
                      </HashLink>
                    </NavDropdown>
                  </li>
                  <li className={style.sidebar_links_item}>
                    <NavDropdown
                      title={t("Corporates")}
                      id="basic-nav-dropdown"
                      className={style.sidebar_links_item_dropdown}
                    >
                      <h6 className={style.sidebar_links_item_dropdown_title}
                        onClick={handlecorporatesClick} // Navigate on click
                        style={{ cursor: 'pointer' }}
                      >{t("services")}</h6>
                      <HashLink onClick={active} className={style.sidebar_links_item_dropdown_link} to="/solutions/corporates#capability-building-programs-for-workforce">
                        {t("CapabilityBuildingProgramsWorkforce")}
                      </HashLink>
                      <HashLink onClick={active} className={style.sidebar_links_item_dropdown_link} to="/solutions/corporates#digitization-of-corporate-training-programs">
                        {t("DigitizationCorporateTrainingPrograms")}
                      </HashLink>
                      <HashLink onClick={active} className={style.sidebar_links_item_dropdown_link} to="/solutions/corporates#digital-strategy-and-consultation">
                        {t("DigitalStrategyConsultation")}
                      </HashLink>

                    </NavDropdown>
                  </li>
                  <li className={style.sidebar_links_item}>
                    <Link to="solutions/teaching" onClick={active}>
                      {t("TeachingArabicForeignLanguage")}
                    </Link>
                  </li>
                  <li className={style.sidebar_links_item}>
                    <Link to="/solutions/investing-startups" onClick={active}>
                      {t("InvestingEdtechStartups")}
                    </Link>
                  </li>
                  <li className={style.sidebar_links_item}>
                    <NavDropdown
                      title={t("publishing")}
                      id="basic-nav-dropdown"
                      className={style.sidebar_links_item_dropdown}
                    >
                      <Row>
                        <Col xs={6}>
                          <h6 className={style.sidebar_links_item_dropdown_title}
                          onClick={() => handlePublishingClick('services')}
                          style={{ cursor: 'pointer' }}
                          >{t("services")}</h6>
                          <HashLink  onClick={(e) => {handleSidebarLinkClick("Services"); active(e)}} className={style.sidebar_links_item_dropdown_link} to="/solutions/publishing#Printing">
                            {t("printing")}
                          </HashLink>

                        </Col>
                        <Col xs={6}>
                          <h6 className={style.sidebar_links_item_dropdown_title}
                          onClick={() => handlePublishingClick('products')}
                          style={{ cursor: 'pointer' }}
                           >{t("Products")}</h6>
                          <HashLink onClick={(e) => {handleSidebarLinkClick("Products"); active(e)}} className={style.sidebar_links_item_dropdown_link} to="/solutions/publishing#Books">
                            {t("books")}
                          </HashLink>
                          <HashLink onClick={(e) => {handleSidebarLinkClick("Products"); active(e)}} className={style.sidebar_links_item_dropdown_link} to="/solutions/publishing#magazines">
                            {t("magazines")}
                          </HashLink>
                  
                        </Col>
                      </Row>
                    </NavDropdown>
                  </li>
                </ul>
              </Col>
              <Col lg={3} md={3} xs={6} className="order-2 order-md-3">
                <Img src="/SidebarProducts.jpg" alt="img" className={`img-fluid ${style.sidebar_img}`} />
              </Col>
            </Row>
          </Container>
          {/* <Img src="/pattern.png" alt="img" className={style.sidebar_pattern} /> */}
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export {
  SidebarAboutUs,
  SidebarEducationalInstitutions,
  SidebarEducationalCertificates,
  SidebarOffering,
};
