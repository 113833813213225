import { lazy, useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Banner = lazy(() => import("./components/banner/Banner"));
const Printing = lazy(() => import("./components/printing/Printing"));
const Contact = lazy(() => import("./components/contact/Contact"));
const Books = lazy(() => import("./components/books/Books"));
const Contact2 = lazy(() => import("./components/contact2/Contact2"));

const Publishing = () => {
  const { hash, state } = useLocation();
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState(() => {
    // Determine the active tab based on hash or state
    const servicesLinks = ["#printing"];
    const productsLinks = ["#books", "#magazines"];

    if (hash) {
      if (servicesLinks.includes(hash)) {
        return "services";
      } else if (productsLinks.includes(hash)) {
        return "products";
      }
    }

    return state?.activeTab || "services"; // Default to 'services' if no hash/state
  });

  useEffect(() => {
    // Scroll to the relevant section when the hash changes
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth" });
        }, 100);
      }
    }
  }, [hash, activeTab]);

  return (
    <>
      <Banner />
      <Tabs
        activeKey={activeTab}
        id="publishing-tabs"
        className="mb-3"
        onSelect={(k) => setActiveTab(k)}
      >
        <Tab eventKey="services" title={t("services")}>
          <Printing />
          <Contact />
        </Tab>
        <Tab eventKey="products" title={t("products")}>
          <Books />
          <Contact2 />
        </Tab>
      </Tabs>
    </>
  );
};

export default Publishing;
