import { Navigate, Route, Routes, useLocation, useParams } from "react-router-dom";
import Layout from "./components/layouts/Layout";
import Home from "./pages/home/Home";
import GroupOverview from "./pages/about/group-overview/GroupOverview";
import { QueryClient, QueryClientProvider } from "react-query";

import Subsidiaries from "./pages/about/subsidiaries/Subsidiaries";
import Markets from "./pages/about/markets/Markets";
import Awards from "./pages/about/awards/Awards";
import History from "./pages/about/history/History";
import SoftwareDevelopment from "./pages/solutions/software-development/SoftwareDevelopment";
import CapabilityBuildingPrograms from "./pages/solutions/capability-building-programs/CapabilityBuildingPrograms";
import InvestingStartups from "./pages/solutions/investing-startups/InvestingStartups";
import VocationalEducation from "./pages/solutions/vocational-education/VocationalEducation";
import Publishing from "./pages/solutions/publishing/Publishing";
import DigitalLearning from "./pages/solutions/digital-learning/DigitalLearning";
import SocialImpact from "./pages/about/social-impact/SocialImpact";
import ProjectsPage from "./pages/projects/Projects";
import Projects from "./pages/about/social-impact/projects/Projects";
import PrintingAcademy from "./pages/educational-institutions/printing-academy/PrintingAcademy";
import AppliedTechnologySchool from "./pages/educational-institutions/applied-technology-school/AppliedTechnologySchool";
import InternationalAcademy from "./pages/educational-institutions/international-academy/InternationalAcademy";
import ExamCenter from "./pages/educational-certificates/exam-center/ExamCenter";
import ContactUs from "./pages/contact-us/ContactUs";
import ProjectDetails from "./pages/projects/project-details/ProjectDetails";
import News from "./pages/news/News";
import NewsDetails from "./pages/news/news-details/newsDetails";
import Teaching from "./pages/solutions/teaching/Teaching";
import Corporates from "./pages/solutions/corporates/Corporates";
import HigherEducation from "./pages/solutions/higherEducation/HigherEducation";
import K12 from "./pages/solutions/k-12/K12";
import Search from "./pages/search/Search";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getDataWebsiteSearch } from "./app/search/searchSlice";
import NotFoundPage from "./pages/not-found/NotFound";
// import axios from "axios";

function App() {
  const client = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  // const location = useLocation(); // للحصول على المسار الحالي
  // useEffect(() => {
  //   const pathAfterBaseURL = location.pathname.slice(1); // Define the variable inside the effect
  //   console.log("Current Path:", pathAfterBaseURL);
  
  //   if (pathAfterBaseURL) {
  //     const fetchSEOData = async () => {
  //       try {
  //         const response = await axios.get(
  //           `https://nahdatmasr.azurewebsites.net/api/Page/GetSEOByCode?code=${pathAfterBaseURL}`
  //         );
  //         console.log("API Response:", response.data);
  //       } catch (error) {
  //         console.error("Error fetching SEO data:", error);
  //       }
  //     };
  
  //     fetchSEOData();
  //   }
  // }, [location]);
  

  const dispatch = useDispatch();

  const { searchKey } = useParams();

  useEffect(() => {
    dispatch(getDataWebsiteSearch(searchKey)); 
    return () => {
      dispatch(getDataWebsiteSearch(""));
    };
  }, [dispatch, searchKey]);

  // State for defaultActiveKey
  const [defaultActiveKey, setDefaultActiveKey] = useState("Services");

  // Function to handle sidebar link click
  const handleSidebarLinkClick = (key) => {
    setDefaultActiveKey(key);
  };

  


  return (
    <>
      <QueryClientProvider client={client}>
        <Layout handleSidebarLinkClick={handleSidebarLinkClick} defaultActiveKey={defaultActiveKey}>
          <Routes>
            <Route exact path="/" element={<Navigate to="/home" />} />
              
            <Route path="/home" element={<Home />} />
            <Route path="about/group-overview" element={<GroupOverview />} />
            <Route path="about/subsidiaries" element={<Subsidiaries />} />
            <Route path="about/markets" element={<Markets />} />
            <Route path="about/awards" element={<Awards />} />
            <Route path="about/history" element={<History />} />
            <Route path="about/social-impact" element={<SocialImpact />} />
            <Route path="about/social-impact/projects/:id" element={<Projects />} />

            <Route path="solutions/k-12" element={<K12 defaultActiveKey={defaultActiveKey}  />} />
            <Route path="solutions/software" element={<SoftwareDevelopment />} />
            <Route path="solutions/capability-building-programs" element={<CapabilityBuildingPrograms />} />
            <Route path="solutions/investing-startups" element={<InvestingStartups />} />
            <Route path="solutions/vocational-education" element={<VocationalEducation />} />
            <Route path="solutions/publishing" element={<Publishing defaultActiveKey={defaultActiveKey} />} />
            <Route path="solutions/digital-learning" element={<DigitalLearning />} />
            <Route path="solutions/teaching" element={<Teaching />} />
            <Route path="solutions/corporates" element={<Corporates />} />
            <Route path="solutions/higher-education" element={<HigherEducation defaultActiveKey={defaultActiveKey} />} />

            <Route path="projects" element={<ProjectsPage />} />
            <Route path="projects/project-details/:id" element={<ProjectDetails />} />

            <Route path="educational-institutions/printing-academy" element={<PrintingAcademy />} />
            <Route path="educational-institutions/applied-technology-school" element={<AppliedTechnologySchool />} />
            <Route path="educational-institutions/international-academy" element={<InternationalAcademy />} />
            <Route path="educational-certificates/exam-center" element={<ExamCenter />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="news" element={<News />} />
            <Route path="news/news-details/:id" element={<NewsDetails />} />

            <Route path="search/:searchKey" element={<Search />} />
            <Route path="not-found" element={<NotFoundPage />} />

          </Routes>
        </Layout>
      </QueryClientProvider>
    </>
  );
}

export default App;
