import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../../components/utils/portal/loading/Loading";
import Error from "../../../../components/utils/portal/error/Error";
import { getDataNewsDetails } from "../../../../app/news/newsSlice";

function NewsDetailsSection() {
  const { id } = useParams();
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { isLoading, newsDetails, error } = useSelector((state) => state.news);

  useEffect(() => {
    dispatch(getDataNewsDetails(id));
  }, [dispatch, id]);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <section className="newsDetails">
      <Container>
        <div className="newsDetails_content">
          <span className="newsDetails_content_date">{newsDetails?.result?.createdDate}</span>
          <h2 className="newsDetails_content_title">
            {i18n.language === "en" ? newsDetails?.result?.titleEn : newsDetails?.result?.titleAr}
          </h2>

          {/* <p className="newsDetails_content_desc" dangerouslySetInnerHTML={{ __html: i18n.language === "en" ? newsDetails?.result?.shortDescriptionEn : newsDetails?.result?.shortDescriptionAr }} /> */}

          <img src={newsDetails?.result?.image} className="newsDetails_content_img" />
          <p className="newsDetails_content_desc" dangerouslySetInnerHTML={{ __html: i18n.language === "en" ? newsDetails?.result?.descriptionEn : newsDetails?.result?.descriptionAr }} />
          {newsDetails?.result?.newsSideSection && <Row className="mt-4">
            <Col lg={8} className="order-lg-1 order-2">
              <div className="newsDetails_contentBox">
                <h3 className="newsDetails_contentBox_title">
                  {i18n.language === "en"
                    ? newsDetails?.result?.newsSideSection.titleEn
                    : newsDetails?.result?.newsSideSection.titleAr}
                </h3>
                <p className="newsDetails_content_desc" dangerouslySetInnerHTML={{ __html: i18n.language === "en" ? newsDetails?.result?.newsSideSection.descriptionEn?.replace(/<p>(&nbsp;|\s|<br\s*\/?>)*<\/p>/g, "") : newsDetails?.result?.newsSideSection.descriptionAr?.replace(/<p>(&nbsp;|\s|<br\s*\/?>)*<\/p>/g, "") }} />


              </div>
            </Col>
            <Col lg={4} className="order-lg-2 order-1 mb-lg-0 mb-3">
              <img src={newsDetails?.result?.newsSideSection.image} className="img-fluid" alt="img newsDetails" />
            </Col>
          </Row>}
        </div>
      </Container>
    </section>
  );
}

export default NewsDetailsSection;
