import React from "react";
import style from "./contactUs.module.scss";
import { useTranslation } from "react-i18next";
import ContactButton from "../utils/links/ContactButton";
import { Fade } from "react-reveal";
import Img from "../utils/img/Img";

function ContactUsSection2({
  linkText,
  link,
  desc,
  contact,
  orange,
  phoneNumber1,
  phoneNumber2,
  phoneNumberDash,
  desc2,
  green,
  moreText,
  download,
  android,
  ios,
  className,
  darkGreen,
  desc3
}) {
  // change language handler
  const { t } = useTranslation();
  return (
    <div className={`${className} ${style.contctUsSection} ${orange && `d-block ${style.orange}`} ${darkGreen && `d-block ${style.darkGreen}`} ${green && `d-block ${style.green}`} ${moreText && style.cust}`}>
      {link ? (
        <div className="d-lg-flex gap-2">
          <Fade left duration={1000}>
            <p className={style.contctUsSection_desc}>{desc}</p>
          </Fade>
          <a href={link} target="_blank" className={style.contctUsSection_link}>
            {linkText}
          </a>
          <Fade left duration={1000}>
            <p className={style.contctUsSection_desc}>{desc3}</p>
          </Fade>
        </div>
      ) : (
          <Fade left duration={1000}>
            <p className={style.contctUsSection_desc}>{desc}</p>
          </Fade>
    
      )}
      {orange && (
        <Fade left duration={1000}>
          <div className="d-md-flex mt-2 gap-1">
            <p className={`mb-md-0 mb-1 ${style.contctUsSection_desc}`}>{desc2}</p>
            {phoneNumber1 && (
              <div className="d-flex gap-2">
                <a href={`tel:${+phoneNumber1}`} className={style.contctUsSection_phoneNumber}>
                  {phoneNumberDash}
                </a>
                <a href={`tel:${+phoneNumber2}`} className={style.contctUsSection_phoneNumber}>
                  {phoneNumber2}
                </a>
              </div>
            )}
          </div>
        </Fade>
      )}
      {contact && (
        <Fade right duration={1000}>
          <ContactButton to="/contact-us">{t("contact")}</ContactButton>
        </Fade>
      )}
      {download && <div className="d-sm-flex d-block gap-3 justify-content-center mt-lg-0 mt-3">
        <a href={`${ios}`} target="_blank" className="mb-sm-0 mb-3 d-block">
          <Img src="/ios.png" alt="Image" className={style.contctUsSection_logo} />
        </a>
        <a href={`${android}`} target="_blank">
          <Img src="/google.png" alt="Image" className={style.contctUsSection_logo} />
        </a>
      </div>}
    </div>
  );
}

export default ContactUsSection2;
