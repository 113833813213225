// import { lazy, useEffect } from "react";
// import Up from "../../../components/utils/up/Up";
// import Services from "./components/services/Services";
// import Products from "./components/products/Products";
// import { Tab, Tabs } from "react-bootstrap";
// import { useLocation } from "react-router-dom";
// import { useTranslation } from "react-i18next";
// const Banner = lazy(() => import("./components/banner/Banner"));




// const K12 = ({defaultActiveKey}) => {
//   const { hash } = useLocation();
//   const { t } = useTranslation();


//   useEffect(() => {
//     const handlePageLoad = () => {
//       if (hash) {
//         const element = document.getElementById(hash.substring(1));
//         if (element) {
//           element.scrollIntoView({ behavior: 'smooth' });
//         }
//       }
//     };
//     // Attach the handler to the window's load event
//     window.addEventListener('load', handlePageLoad);

//     // Cleanup the event listener
//     return () => {
//       window.removeEventListener('load', handlePageLoad);
//     };
//   }, [hash])
//   return (
//     <>
//       <Banner />
//       <Tabs
//         defaultActiveKey={defaultActiveKey === "Services" ? "Services" : "Products"}
//         id="uncontrolled-tab-example"
//       >
//         <Tab eventKey="Services" title={t("tapservices")}>
//           <Services />
//         </Tab>
//         <Tab eventKey="Products" title={t("tapproduct")}>
//           <Products />
//         </Tab>
//       </Tabs>
//       <Up />
//     </>
//   );
// };

// export default K12;


import { lazy, useEffect, useState, useRef } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Up from "../../../components/utils/up/Up";
import Services from "./components/services/Services";
import Products from "./components/products/Products";

const Banner = lazy(() => import("./components/banner/Banner"));

const K12 = () => {
  const { hash, state } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  
  const [activeTab, setActiveTab] = useState(() => {
    // تحديد التبويب النشط بناءً على الرابط
    if (hash) {
      // قائمة روابط الخدمات
      const servicesLinks = [
        '#services-curricula-development',
        '#services-Translation',
        '#services-capability',
        '#services-digitization-of-content',
        '#learning-management-systems',
        '#mobile-applications-development',
        '#digital-strategy-and-consultation'
      ];

      // قائمة روابط المنتجات
      const productsLinks = [
        '#Al-Adwaa',
        '#Rehlatie',
        '#qessatie',
        '#international-curricula-programs',
        '#leveled-readers',
        '#calligraphy-books',
        '#life-skills-and-character-development',
        '#early-childhood-programs',
        '#islamic-religion-curriculum',
        '#school-supplies'
      ];

      // تحديد التبويب
      if (servicesLinks.includes(hash)) {
        return 'services';
      } else if (productsLinks.includes(hash)) {
        return 'products';
      }
    }

    // القيمة الافتراضية إذا لم يتم تحديد التبويب
    return state?.activeTab || 'services';
  });

  useEffect(() => {
    // التأكد من التمرير للقسم المحدد
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth' });
        }, 100);
      }
    }
  }, [hash, activeTab]);

  return (
    <>
      <Banner />
      <Tabs
        activeKey={activeTab}
        id="k12-tabs"
        className="mb-3"
        onSelect={(k) => setActiveTab(k)}
      >
        <Tab eventKey="services" title={t("services")}>
          <Services />
        </Tab>
        <Tab eventKey="products" title={t("Products")}>
          <Products />
        </Tab>
      </Tabs>
      <Up />
    </>
  );
};

export default K12;