import { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

const useTitle = (code) => {
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { i18n } = useTranslation(); // Get the current language

  useEffect(() => {
    const fetchTitle = async () => {
      try {
        const response = await axios.get(
          "https://nahdatmasr.azurewebsites.net/api/Titles/GetTitleByCode",
          {
            params: {
              code, // Dynamically pass the code
            }
          }
        );

        // Set title based on language (English or Arabic)
        const title = i18n.language === "en" ? 
          response.data?.result?.titleEn : 
          response.data?.result?.titleAr;
        
        setTitle(title);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching title:", error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchTitle();
  }, [i18n.language, code]); // Re-fetch title when the language or code changes

  return { title, loading, error };
};

export default useTitle;
