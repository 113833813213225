import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container } from "react-bootstrap";

import Error from "../../../../../components/utils/portal/error/Error";
import Loading from "../../../../../components/utils/portal/loading/Loading";
import { getDataPositiveImpact } from "../../../../../app/about/socialImpact/socialImpactSlice";
import { useTranslation } from "react-i18next";
import Title from "../../../../../components/title/Title";
import { Fade } from "react-reveal";
import useTitle from "../../../../../hooks/useTitle";

function PositiveImpact() {
  // fetch data
  const dispatch = useDispatch();
  const { isLoading, positiveImpact, error } = useSelector((state) => state.socialImpact);
  const { title, loading: titleLoading, error: titleError } = useTitle("MakingPositiveImpact");
  useEffect(() => {
    dispatch(getDataPositiveImpact());
  }, []);

  // change language handler
  const { i18n, t } = useTranslation();

  // loading
  if (isLoading || titleLoading) {
    return <Loading />;
  }
  if (error || titleError) {
    return <Error error={error?.message || titleError} />;
  }
  return (
    <section className="PositiveImpact pb-4">
      <Container>
        {/* <Title title={t("MakingPositiveImpact")} className="mainColor" /> */}
        <Title  title={title} className="mainColor" />
        <Fade up duration={1800} delay={500}>
          {/* <p>
            {`${ i18n.language === "en" ? positiveImpact?.result?.descriptionEn : positiveImpact?.result?.descriptionAr}`}
          </p> */}

          <p  dangerouslySetInnerHTML={{__html: `${ i18n.language === "en" ? positiveImpact?.result?.descriptionEn : positiveImpact?.result?.descriptionAr}`}} />

        </Fade>
      </Container>
    </section>
  );
}

export default PositiveImpact;
