import React from "react";
import style from "./mainBanner.module.scss";
import MainLink from "../utils/links/MainLink";
import { Fade } from "react-reveal";
import Img from "../utils/img/Img";

const MainBanner = ({ background, title, subTitle, btn_link, video, link, linkText ,titleClassName}) => {
  return (
    <div className={style.mainBanner}>
      {video ? (
        <video
          className={style.mainBanner_video}
          width="100%"
          height="100%"
          loop
          muted
          playsInline
          autoPlay="autoplay"
          controlsList="nofullscreen nodownload"
        >
          <source src={video} type="video/mp4" />
        </video>
      ) : (
        <Img className={style.mainBanner_img} src={background} alt="img" />
      )}

      <div className={style.mainBanner_content}>
        <Fade down duration={2500}>
        <h1 className={`${style.mainBanner_content_title} ${titleClassName || ""}`}>
        {title}</h1>
        </Fade>
        {subTitle && (
          <Fade up duration={2500}>
            <h3
              className={style.mainBanner_content_subTitle}
              dangerouslySetInnerHTML={{
                __html: subTitle?.replace(/<p[^>]*>\s*<\/p>/g, ""),
              }}
            />
          </Fade>
        )}

        {link && (
          <Fade up duration={2500} delay={500}>
            <MainLink href={btn_link}>{linkText}</MainLink>
          </Fade>
        )}
      </div>
    </div>
  );
};

export default MainBanner;
