import { lazy, useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Up from "../../../components/utils/up/Up";
import Services from "./components/services/Services";
import Products from "./components/products/Products";

const Banner = lazy(() => import("./components/banner/Banner"));

const HigherEducation = () => {
  const { hash, state } = useLocation();
  const { t } = useTranslation();
  
  const [activeTab, setActiveTab] = useState(() => {
    // Determine active tab based on URL
    if (hash) {
      // Services links list
      const servicesLinks = [
        '#capability-building-for-educators',
        '#digitization-of-content'
      ];

      // Products links list
      const productsLinks = [
        '#elt'
      ];

      // Determine tab
      if (servicesLinks.includes(hash)) {
        return 'services';
      } else if (productsLinks.includes(hash)) {
        return 'products';
      }
    }

    // Default value if tab not determined
    return state?.activeTab || 'services';
  });
  

  useEffect(() => {
    // Ensure scrolling to the specified section
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth' });
        }, 100);
      }
    }
  }, [hash, activeTab]);

  return (
    <>
      <Banner />
      <Tabs
        activeKey={activeTab}
        id="higher-education-tabs"
        className="mb-3"
        onSelect={(k) => setActiveTab(k)}
      >
        <Tab eventKey="services" title={t("services")}>
          <Services />
        </Tab>
        <Tab eventKey="products" title={t("Products")}>
          <Products />
        </Tab>
      </Tabs>
      <Up />
    </>
  );
};

export default HigherEducation;